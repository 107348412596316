import React from 'react';

export default function Footer() {
    return (
        <footer className="font-sans text-base sm:px-0 px-6 mt-8">
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row">
                    <div className="flex-auto" />
                </div>
                <hr />
                <div className="flex flex-row justify-between pb-6 flex-initial text-white">
                    <p className="mb-6 md:mb-0">
                        <span>© BioLib 2024</span>
                    </p>
                    <span>licensing@biolib.com</span>
                </div>
            </div>
        </footer>
    );
}
