import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { api } from './api/api';
import './global.css';

interface IProps {
    element: React.ReactNode;
}

const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export function wrapRootElement(props: IProps) {
    return <Provider store={store}>{props.element}</Provider>;
}
