import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';

type LayoutProps = {
    children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="flex flex-col min-h-screen bg-gradient-to-r from-slate-800 to-cyan-700">
                <div className="flex flex-col flex-1 container mx-auto lg:px-14 md:px-10 sm:px-6 overflow-hidden max-w-5xl">
                    <Header />
                    <div className="flex-1">
                        <div className="rounded-lg bg-white shadow">
                            <div className="w-full mx-auto">
                                <div className="py-10 px-8">{children}</div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Layout;
