import React from 'react';
import { Link } from 'gatsby';
import BioLibLogoWhite from '../images/biolib_logo_light.svg';

export default function Header() {
    return (
        <div className="flex flex-row items-center justify-between sm:px-0 px-6 ">
            <div className="py-6">
                <Link to="/deeptmhmm">
                    <img alt="BioLib Logo" className="h-8 inline" draggable={false} src={BioLibLogoWhite} />
                </Link>
            </div>
            <div className="flex-initial">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:licensing@biolib.com"
                    className="mb-6 md:mb-0 text-white"
                >
                    <span>Contact Us</span>
                </a>
                <p className="text-white"></p>
            </div>
        </div>
    );
}
